<template>
    <div
        class="section"
        :data-layout="layout"
        :data-orientation="orientation"
    >
        <SfPageContainer class="section__container">
            <h2
                v-if="!!$slots.title"
                class="section__title"
            >
                <slot name="title" />
            </h2>

            <div
                v-if="!!$slots.aside"
                class="section__aside"
            >
                <slot name="aside" />
            </div>

            <div class="section__body">
                <slot />
            </div>
        </SfPageContainer>
    </div>
</template>

<script>
import SfPageContainer from '~/patterns/organisms/page-container/page-container';

export default {
    components: {
        SfPageContainer
    },

    props: {
        layout: {
            type: String,
            default: 'default',
            validator(value) {
                return [
                    'default',
                    'text-image',
                    'video',
                    'home-intro',
                    'rich-text',
                    '2-columns',
                    'stappenplan',
                    'call-to-action'
                ].includes(value);
            }
        },
        orientation: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'right'].includes(value);
            }
        }
    }
};
</script>

<style lang="less" src="./section.less" />
